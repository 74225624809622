import { useState } from 'react';

import { NextRouter } from 'next/router';

import { USER_ROLE } from '@@/StoreAndStoreOperator/constants';
import { Store } from '@@/StoreAndStoreOperator/entities/store';

import { ROUTES } from '@/constants';

import Typograph from '@/components/typography';

import { handleRedirectTradeIn } from '.';

type MenuHeaderProps = {
  store?: Store;
  router: NextRouter;
  logout: () => void;
};

const MenuHeader = ({ router, logout, store }: MenuHeaderProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const isAdmin = store?.membership?.role === USER_ROLE.STORE_OPERATOR_ADMIN;

  const items = [
    {
      label: (
        <>
          Desempenho
          <Typograph.Span variant="extra-small" className="ml-1 text-on-surface-light-medium">
            Gerente
          </Typograph.Span>
        </>
      ),
      onClick: () => router.push(ROUTES.OPERATOR_PORTAL_DASHBOARD),
      isEnable: isAdmin,
    },
    {
      label: (
        <>
          Convidar Operador
          <Typograph.Span variant="extra-small" className="ml-1 text-on-surface-light-medium">
            Gerente
          </Typograph.Span>
        </>
      ),
      onClick: () => router.push(ROUTES.OPERATOR_PORTAL_INVITE),
      isEnable: isAdmin,
    },
    {
      label: 'Reiniciar Trade-in',
      onClick: () => handleRedirectTradeIn(router, ROUTES.SEARCH),
      isEnable: true,
    },
    {
      label: 'Sair',
      onClick: () => logout(),
      isEnable: true,
    },
  ];

  return (
    <>
      {isOpen && (
        <div className="fixed left-0 top-0 h-screen w-screen">
          <button className="h-full w-full" onClick={() => setIsOpen(false)} />
        </div>
      )}

      <div className="relative">
        <button onClick={() => setIsOpen(!isOpen)}>
          <HamburguerIcon />
        </button>

        {isOpen && (
          <div className="absolute right-0 top-7 z-20 rounded-md border border-neutral-5 bg-white shadow-lg">
            {items.map((item, index) => (
              <HeaderOption key={item.label.toString() + index} item={item} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

type Item = {
  label: JSX.Element | string;
  onClick: () => Promise<boolean> | void;
  isEnable: boolean;
};

const HeaderOption = ({ item }: { item?: Item }) => {
  if (!item || !item.isEnable) return null;

  return (
    <button onClick={item.onClick} className="w-full px-4 py-2 text-left hover:bg-neutral-3">
      <Typograph.Span variant="small-body" className="whitespace-nowrap font-normal capitalize text-on-surface-light">
        {item.label}
      </Typograph.Span>
    </button>
  );
};

const HamburguerIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
    <path d="M0 0H18V2H0V0ZM0 7H18V9H0V7ZM0 14H18V16H0V14Z" fill="#646464" />
  </svg>
);

export default MenuHeader;
