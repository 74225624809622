import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { includes } from 'ramda';

import { tv } from 'tailwind-variants';

import { useUserAuth } from '@/context/user-auth-provider';

import TopHeaderOperatorInfos from '@@/StoreAndStoreOperator/components/top-header-operator-infos';

import { ROUTES } from '@/constants';

type Props = {
  children: React.ReactNode;
  className?: string;
  withoutHeader?: boolean;
  disableCenterDesk?: boolean;
  skipUserAuthCheck?: boolean;
  footer?: React.ReactNode;
};

const container = tv({
  base: 'w-full h-full',
  variants: {
    type: {
      center: 'md:flex md:flex-col md:items-center md:justify-center',
    },
    header: {
      withHeader: 'min-h-[calc(100vh-52px)] md:min-h-[calc(100vh-41px)] pt-3',
      withoutHeader: 'min-h-screen pt-10 md:pt-0',
    },
    footer: {
      true: 'min-h-[calc(100vh-62px)] md:min-h-[calc(100vh-44px)]',
    },
  },
  compoundVariants: [
    {
      header: 'withHeader',
      footer: true,
      class: 'min-h-[calc(100vh-104px)] md:min-h-[calc(100vh-88px)] pt-3 pb-6 md:pb-8',
    },
    {
      header: 'withoutHeader',
      footer: true,
      class: 'min-h-[calc(100vh-44px)] md:min-h-[calc(100vh-62px)] pt-10 md:pt-0',
    },
  ],
});

const Container = ({
  children,
  className,
  withoutHeader = false,
  disableCenterDesk = false,
  skipUserAuthCheck = false,
  footer,
}: Props) => {
  const { storeData, isSignedIn } = useUserAuth();
  const [isClient, setIsClient] = useState(false);
  const { push, pathname } = useRouter();

  const shouldRedirectNotAuthed =
    !skipUserAuthCheck && !storeData && !includes(pathname, [ROUTES.OPERATOR_PORTAL, ROUTES.LOGIN, ROUTES.ONLY_ADMIN]);

  const shouldRenderHeader = !withoutHeader && isClient && isSignedIn;

  useEffect(() => {
    setIsClient(true);

    if (shouldRedirectNotAuthed) push(ROUTES.OPERATOR_PORTAL);
  }, [storeData]);

  return (
    <>
      {shouldRenderHeader && <TopHeaderOperatorInfos />}

      <div className={`container w-full px-4 md:mx-auto md:max-w-xl ${className}`}>
        <div
          className={container({
            type: disableCenterDesk ? undefined : 'center',
            header: withoutHeader ? 'withoutHeader' : 'withHeader',
            footer: !!footer,
          })}>
          <div className={`h-full w-full ${!withoutHeader && 'py-2'}`}>{children}</div>
        </div>
      </div>

      {footer}
    </>
  );
};

export default Container;
