import { ComponentProps } from 'react';

import { VariantProps, tv } from 'tailwind-variants';

const typography = tv({
  base: 'font-bold text-on-surface-light',
  variants: {
    variant: {
      h1: 'text-4xl',
      h2: 'text-3xl',
      h3: 'text-2xl',
      h4: 'text-xl',
      h5: 'text-base',
      'medium-body': 'font-normal text-sm',
      'big-body': 'font-normal text-base',
      'extra-small': 'font-normal text-xxs',
      'small-body': 'font-normal text-xs',
      link: 'font-medium text-forest-green underline cursor-pointer',
      'link-gray': 'font-medium underline cursor-pointer text-sm text-on-surface-light-medium',
    },
  },
  defaultVariants: {
    variant: 'h1',
  },
});

type TypographyProps = ComponentProps<'h1'> & VariantProps<typeof typography>;

const H1 = ({ variant = 'h1', children, className, ...props }: TypographyProps) => {
  return (
    <h1 className={typography({ variant, className })} {...props}>
      {children}
    </h1>
  );
};

const H2 = ({ variant = 'h2', children, className, ...props }: TypographyProps) => {
  return (
    <h2 className={typography({ variant, className })} {...props}>
      {children}
    </h2>
  );
};

const H3 = ({ variant = 'h3', children, className, ...props }: TypographyProps) => {
  return (
    <h3 className={typography({ variant, className })} {...props}>
      {children}
    </h3>
  );
};

const H4 = ({ variant = 'h4', children, className, ...props }: TypographyProps) => {
  return (
    <h4 className={typography({ variant, className })} {...props}>
      {children}
    </h4>
  );
};

const Span = ({ variant = 'big-body', children, className, ...props }: TypographyProps) => {
  return (
    <span className={typography({ variant, className })} {...props}>
      {children}
    </span>
  );
};

const B = ({ variant = 'big-body', children, className, ...props }: TypographyProps) => {
  return (
    <b className={typography({ variant, className })} {...props}>
      {children}
    </b>
  );
};

const Text = ({ variant = 'medium-body', children, className, ...props }: TypographyProps) => {
  return (
    <p className={typography({ variant, className })} {...props}>
      {children}
    </p>
  );
};

const Typograph = { H1, H2, H3, H4, Span, Text, B };
export default Typograph;
