import { FormattedMessage } from 'react-intl';

import { NextRouter, useRouter } from 'next/router';

import { useUserAuth } from '@/context/user-auth-provider';

import { ROUTES } from '@/constants';
import { routesRestartWithoutGiveUp } from '@/constants/restart-routes';

import { getDisplayedDocument } from '@/utils/input-masks';

import MenuHeader from './menu-header';

export const handleRedirectTradeIn = (router: NextRouter, path: string) => {
  const currentPath = router.pathname;
  const isRestartRoute = routesRestartWithoutGiveUp.some((route) => currentPath === '/' || currentPath.includes(route));

  if (isRestartRoute) {
    router.push(path, undefined, { shallow: true });
    return;
  }

  router.replace(`${ROUTES.TRADE_IN_GIVE_UP}?redirectTo=${path}`, undefined, { shallow: true });
};

const TopHeaderOperatorInfos = () => {
  const router = useRouter();
  const { logout, userData, storeData } = useUserAuth();

  const fullName = `${userData?.firstName} ${userData?.lastName}`;

  return (
    <div className="border-b border-b-neutral-5 py-2">
      <div className="mx-4 flex items-center justify-between text-center md:mx-auto md:max-w-lg">
        <div className="flex w-fit flex-col text-left leading-4 sm:flex-row sm:items-center">
          <div className="flex w-fit items-center">
            <span className="text-nowrap text-xxs font-normal capitalize text-on-surface-light-medium">
              <strong>Loja: </strong>
              {storeData?.name}
            </span>

            <button className="pb-0.5 pl-2" onClick={() => handleRedirectTradeIn(router, ROUTES.OPERATOR_PORTAL)}>
              <EditIcon />
            </button>

            <span className="hidden text-nowrap px-2 text-xxs font-normal capitalize text-on-surface-light-medium sm:block">
              {'|'}
            </span>
          </div>

          <div className="flex items-center sm:max-w-full">
            <div className="max-w-[40vw] overflow-hidden text-ellipsis text-nowrap sm:max-w-full">
              <span className="text-xxs font-normal capitalize text-on-surface-light-medium">
                <strong>
                  <FormattedMessage id="operator" />
                  {': '}
                </strong>
                {fullName}
              </span>
            </div>

            <span className="px-2 text-xxs font-normal capitalize text-on-surface-light-medium">|</span>

            <div className="max-w-[30vw] overflow-hidden text-ellipsis text-nowrap sm:max-w-full">
              <span className="text-xxs font-normal capitalize text-on-surface-light-medium">
                <strong>{userData?.document?.type}: </strong>
                {getDisplayedDocument(userData?.document?.value as string)}
              </span>
            </div>
          </div>
        </div>

        <MenuHeader logout={logout} router={router} store={storeData} />
      </div>
    </div>
  );
};

const EditIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
    <path
      d="M9.17133 0.997949L7.838 2.33128H1.33333V11.6646H10.6667V5.15928L12 3.82595V12.3313C12 12.5081 11.9298 12.6777 11.8047 12.8027C11.6797 12.9277 11.5101 12.998 11.3333 12.998H0.666667C0.489856 12.998 0.320286 12.9277 0.195262 12.8027C0.0702379 12.6777 0 12.5081 0 12.3313V1.66462C0 1.48781 0.0702379 1.31824 0.195262 1.19321C0.320286 1.06819 0.489856 0.997949 0.666667 0.997949H9.17133ZM11.6567 0.397949L12.6 1.34062L6.47133 7.46928L5.53 7.47062L5.52867 6.52662L11.6567 0.397949Z"
      fill="#646464"
    />
  </svg>
);

export default TopHeaderOperatorInfos;
