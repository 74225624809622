export const getDisplayedDocument = (document: string) => {
  if (!document) return '';

  const limit = 18;

  document = document.slice(0, limit).replace(/\D/g, '');

  if (document.length <= 11) {
    document = document.replace(/(\d{3})(\d)/, '$1.$2');
    document = document.replace(/(\d{3})(\d)/, '$1.$2');
    document = document.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  } else {
    document = document.replace(/^(\d{2})(\d)/, '$1.$2');
    document = document.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    document = document.replace(/\.(\d{3})(\d)/, '.$1/$2');
    document = document.replace(/(\d{4})(\d)/, '$1-$2');
  }

  return document;
};

export const getDisplayPhone = (value: string) => {
  if (!value) return '';
  value = value.slice(0, 15);
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d)/, '($1) $2');
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');
  return value;
};

export const getDisplayCep = (value: string) => {
  if (!value) return '';
  value = value.slice(0, 9);
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{5})(\d)/, '$1-$2');
  return value;
};
