import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';

import { PARAM_ORIGIN, ROUTES } from '@/constants';
import { NAVIGATION_FLOW } from '@/constants/navigation-flow';

import { getRouteWithOrigin } from '@/utils';

function getParams(data: { [x: string]: string | number }) {
  return Object.keys(data)
    .map((key) => `${key}=${encodeURIComponent(data[key])}`)
    .join('&');
}

export const useNavigationFlow = () => {
  const { push } = useRouter();
  const pathname = usePathname();

  const navigation = NAVIGATION_FLOW[pathname as ROUTES];

  const nextPage = (route: ROUTES, params?: PARAM_ORIGIN | { [x: string]: string | number } | null) => {
    //this is necessary cause router can't be used in server side
    if (typeof window === 'undefined') return null;

    if (navigation.next.includes(route)) {
      if (!params) {
        push(route, undefined);
      } else if (typeof params === 'object') {
        const paramsUrl = getParams(params);
        push(`${route}?${paramsUrl}`);
      } else push(getRouteWithOrigin(route, params as PARAM_ORIGIN));
    } else console.error('Invalid route', route);
  };

  const previousPage = (route: ROUTES, params?: PARAM_ORIGIN | { [x: string]: string | number } | null) => {
    if (navigation.previous.includes(route)) {
      if (!params) push(route, undefined);
      else if (typeof params === 'object') {
        const paramsUrl = getParams(params);
        push(`${route}?${paramsUrl}`);
      } else push(getRouteWithOrigin(route, params));
    } else {
      console.error('Invalid route', route);
    }
  };

  return { nextPage, previousPage };
};
